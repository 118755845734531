export const ALL = 1;
export const BUILDING_COMPLEX = 2;
export const BUILDING = 3;
export const MANAGEMENT_COMPANY = 4;

export default Object.freeze({
  ALL,
  BUILDING_COMPLEX,
  BUILDING,
  MANAGEMENT_COMPANY,
});
