<template>
  <v-form ref="form" v-model="valid" @input="$emit('valid', $event)">
    <v-row>
      <v-col cols="12">
        <v-text-field
          name="name"
          :value="value.name"
          :counter="65"
          :rules="rules.textLine65"
          :readonly="readonly"
          :error-messages="errors.name"
          :label="`${$t('ITEM.TITLE')}`"
          @input="updateValue('name', $event)"
        ></v-text-field>

        <v-textarea
          name="description"
          :value="value.description"
          :readonly="readonly"
          :rules="rules.textDescription"
          :counter="1500"
          :error-messages="errors.description"
          :label="`${$t('ITEM.DESCRIPTION')}`"
          rows="2"
          @input="updateValue('description', $event)"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <slot name="buttons" :valid="valid" />
    </v-row>
  </v-form>
</template>

<script>
import { textLine65, textDescription } from '@/rules';

export default {
  name: 'MainForm',

  inject: ['register', 'unregister'],

  props: {
    value: {
      type: Object,
      default: () => ({
        name: '',
      }),
    },
    languageId: {
      type: Number,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      valid: true,
      rules: {
        textLine65,
        textDescription,
      },
    };
  },

  mounted() {
    this.register(this);
  },

  beforeDestroy() {
    this.unregister(this);
  },

  methods: {
    updateValue(key, value) {
      this.$emit('update-prop', key, value);
      this.$emit('input', {
        ...this.value,
        language_id: this.languageId,
        [key]: value,
      });
    },
  },
};
</script>
