import qs from 'qs';

import { Http } from './http.init';
import { ResponseWrapper, ErrorWrapper } from './util';
import { merge } from 'lodash';

export class UploadService {
  static request(meta = { auth: true, base_url: true }) {
    return new Http(meta);
  }

  static responseWrapper(...rest) {
    return new ResponseWrapper(...rest);
  }

  static errorWrapper(...rest) {
    return new ErrorWrapper(...rest);
  }

  static querystring(obj) {
    return qs.stringify(obj, {
      encode: false,
    });
  }

  static async postFormData(path, data, config) {
    try {
      const response = await this.request({ auth: true }).post(
        path,
        data,
        merge({ headers: { 'Content-Type': 'multipart/form-data' } }, config)
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async remove(path) {
    try {
      const response = await this.request({ auth: true }).delete(path);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
