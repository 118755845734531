<template>
  <v-file-input
    ref="input"
    :value="files"
    :loading="isLoading"
    :multiple="multiple"
    :error-messages="getErrorsMessages"
    v-bind="$attrs"
    clearable
    chips
    v-on="inputListeners"
  >
    <template v-slot:selection="{ text, index, file }">
      <v-chip
        small
        close
        :color="file._upload.status === $options.ERROR ? 'error' : ''"
        :text-color="file._upload.status === $options.ERROR ? 'white' : ''"
        @click:close="removeFile(file)"
      >
        <v-progress-linear
          v-if="file._upload.status === $options.UPLOADING"
          :value="file._upload.progress"
          class="chip-progress"
          height="100"
        ></v-progress-linear>
        {{ text }}
      </v-chip>
    </template>

    <template #progress>
      <v-progress-linear v-model="processAll" absolute />
    </template>
  </v-file-input>
</template>

<script>
import { get, isEqual } from 'lodash';
import { UploadService } from '@/services/upload.service';
import { CancelToken } from '@/services/http.init';

const ADDED = 'added';
const UPLOADING = 'uploading';
const ERROR = 'error';
const SUCCESS = 'success';

const modifyFile = function (file, status = SUCCESS, data = {}) {
  file._upload = {
    status: status,
    total: file.size,
    loaded: status === SUCCESS ? file.size : 0,
    progress: 0,
    data: data,
    errors: [],
    cancel: () => {},
  };

  return file;
};

const isFile = (input) => 'File' in window && input instanceof File;

export default {
  ADDED,
  UPLOADING,
  ERROR,
  SUCCESS,

  name: 'AutouploadFileInput',

  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    value: {
      type: [Object, Array],
      default: undefined,
    },
    url: {
      type: String,
      required: true,
    },
    destroyUrl: {
      type: String,
      default: '/uploads',
    },
    name: {
      type: String,
      default: 'file',
    },
    itemName: {
      type: String,
      default: 'original_name',
    },
    itemSize: {
      type: String,
      default: 'size',
    },
    itemType: {
      type: String,
      default: 'mime',
    },
    destroyed: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    duplicateCheck: {
      type: Boolean,
      default: true,
    },
    errorMessages: {
      type: [String, Array],
      default: () => [],
    },
    parallelUploadsCount: {
      type: Number,
      default: 3,
    },
  },

  data() {
    return {
      files: [],
    };
  },

  computed: {
    inputListeners: function () {
      return Object.assign({}, this.$listeners, {
        change: this.onFileChange,
      });
    },
    uploading() {
      return Boolean(this.uploadingItems.length);
    },
    isLoading() {
      return this.loading && this.uploading;
    },
    currentValue() {
      return this.multiple ? this.value : this.value ? [this.value] : [];
    },
    uploadValue() {
      return this.multiple
        ? this.successItems.map((i) => i._upload.data)
        : this.successItems.length
        ? this.successItems[0]._upload.data
        : undefined;
    },
    uploadIds() {
      return this.multiple
        ? this.uploadValue.map((i) => i.id)
        : this.uploadValue
        ? this.uploadValue.id
        : null;
    },
    addedItems() {
      return this.files.filter((file) => file._upload.status === ADDED);
    },
    uploadingItems() {
      return this.files.filter((file) => file._upload.status === UPLOADING);
    },
    successItems() {
      return this.files.filter((file) => file._upload.status === SUCCESS);
    },
    errorItems() {
      return this.files.filter((file) => file._upload.status === ERROR);
    },
    loadedAll() {
      return this.files.reduce((acc, i) => acc + i._upload.loaded, 0);
    },
    totalAll() {
      return this.files.reduce((acc, i) => acc + i._upload.total, 0);
    },
    processAll() {
      return Math.round((this.loadedAll * 100) / this.totalAll);
    },
    isFinished() {
      return this.files.every((i) => i._upload.status === SUCCESS);
    },
    errors() {
      return this.errorItems.reduce(
        (acc, i) => acc.concat(i._upload.errors),
        []
      );
    },
    getErrorsMessages() {
      return [].concat(this.errorMessages, this.errors);
    },
  },

  watch: {
    deep: true,
    immediate: true,
    value: {
      handler: function () {
        this.files = this.currentValue.map((item) => {
          return modifyFile(
            {
              ...item,
              name: get(item, this.itemName),
              size: get(item, this.itemSize),
              type: get(item, this.itemType),
            },
            SUCCESS,
            item
          );
        });
      },
    },
  },

  methods: {
    onFileChange(files) {
      if (files.length) {
        if (isEqual(files, this.files)) {
          return;
        }

        for (let file of files) {
          if (isFile(file)) {
            if (this.isDuplicateFile(file)) {
              this.$refs.input.lazyValue = this.files;
            } else {
              this.addFile(file);
            }
          }
        }

        this.processUpload();
      } else {
        this.clear(); // clear
      }
    },

    isDuplicateFile(file) {
      if (this.duplicateCheck && this.files.length) {
        for (let i = 0; i < this.files.length; i++) {
          if (
            this.files[i].name === file.name &&
            this.files[i].size === file.size &&
            this.files[i].type === file.type
          ) {
            return true;
          }
        }
      }

      return false;
    },

    addFile(file) {
      modifyFile(file, ADDED);
      this.files.push(file);
    },

    processUpload() {
      while (
        this.addedItems.length > 0 &&
        this.uploadingItems.length < this.parallelUploadsCount
      ) {
        this.uploadFile(this.addedItems.shift());
      }

      this.updateValue();
    },

    uploadFile(file) {
      const form = new FormData();
      const { token: cancelToken, cancel } = CancelToken.source();

      file._upload.status = UPLOADING;
      file._upload.cancel = cancel;
      this.updateFile(file);

      const onUploadProgress = (progressEvent) => {
        file._upload.total = progressEvent.total;
        file._upload.loaded = progressEvent.loaded;
        file._upload.progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        this.updateFile(file);
        this.$emit('progress', file, file._upload.progress);
      };
      form.append(this.name, file, file.name);

      this.$emit('uploading', file);

      UploadService.postFormData(this.url, form, {
        onUploadProgress,
        cancelToken,
      })
        .then(({ data }) => {
          file._upload.status = SUCCESS;
          file._upload.data = data;
          this.$emit('uploaded', file);
        })
        .catch(({ response }) => {
          if (response) {
            const { status, data } = response;

            file._upload.status = ERROR;

            if (status === 422) {
              file._upload.errors = data.errors[this.name].map((error) => {
                return error.replaceAll(`"${this.name}"`, `"${file.name}"`);
              });
            }
          }

          this.$emit('failed', file, file._upload.errors);
        })
        .finally(() => {
          this.updateFile(file);
          this.processUpload();
        });
    },

    clear() {
      this.files.forEach(this.removeFile);
    },

    updateFile(file) {
      const index = this.files.findIndex((i) => i === file);

      if (index > -1) {
        this.$set(this.files, index, file);
      }
    },

    removeFile(file) {
      if (file._upload.status === UPLOADING) {
        file._upload.cancel();
      }

      this.destroyFile(file);
      this.files = this.files.filter((i) => i !== file);
      this.$emit('remove', file);
      this.updateValue();
    },

    destroyFile(file) {
      if (!this.destroyed) {
        return;
      }

      if (file._upload.status === SUCCESS) {
        UploadService.remove(`${this.destroyUrl}/${file._upload.data.id}`);
      }
    },

    updateValue() {
      if (this.isFinished) {
        this.emitter();
      }
    },

    emitter() {
      this.$emit('input', this.uploadIds);
      this.$emit('change', this.uploadValue);
    },
  },
};
</script>
<style lang="scss">
.chip-progress {
  position: absolute;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  opacity: 0.4;
}
</style>
